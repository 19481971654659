/* eslint-disable eqeqeq */

import {
  mapActions, mapGetters, mapMutations,
} from 'vuex';
import _ from 'lodash';
import {
  Sidebar,
  ChallengeRanking,
  Loader,
} from '@/components';

export default {
  metaInfo: {
    title: 'Challenge detailpage',
  },
  name: 'Activity',
  components: {
    [Sidebar.name]: Sidebar,
    [ChallengeRanking.name]: ChallengeRanking,
    [Loader.name]: Loader,
  },
  props: {
  },
  data: () => ({
    activetab: 1,
    activegroup: 0,
    imageName: '',
    rankingPage: 1,
    selectedDiscipline: '',
    isWatermanChallenge: true,
    user: null,
    challenge: '',
  }),
  computed: {
    ...mapGetters('challenge', {
      challengeDetails: 'getChallenge',
      challenges: 'getChallengeList',
      ranking: 'getRanking',
      rankingFilter: 'getRankingFilter',
      buttonRankingIsLoading: 'getButtonLoadingState',
      isLoadingRanking: 'getLoadingState',
      getUserRank: 'getUserRank',
    }),
    ...mapGetters('discipline', {
      disciplines: 'getDisciplineList',
      buttonDisciplineIsLoading: 'getButtonLoadingState',
      isLoadingDisciplines: 'getLoadingState',
    }),
    ...mapGetters('group', {
      groups: 'getUserGroupList',
      getUserGroupFilter: 'getUserGroupFilter',
      isLoadingGroups: 'getLoadingState',
      buttonGroupsIsLoading: 'getButtonLoadingState',
    }),
    ...mapGetters('account', {
      currentUser: 'getCurrentUser',
    }),
    formattedDisciplineList() {
      const formattedDisciplineList = [];

      const empty = { id: 0, name: 'Alle disciplines' };
      formattedDisciplineList.push(empty);

      this.disciplines.forEach((discipline) => {
        if (discipline.level === 0) {
          const d = _.clone(discipline);
          formattedDisciplineList.push(d);
        }
      });
      return formattedDisciplineList;
    },
    getIndex() {
      return this.ranking.results.map((u) => u.id).indexOf(this.currentUser.id);
    },

  },
  methods: {
    ...mapActions('challenge', {
      initialiseChallenge: 'FETCH_DATA_CHALLENGE',
      initialiseChallenges: 'FETCH_DATA_CHALLENGE_LIST',
      initialiseYearRanking: 'FETCH_DATA_YEAR_RANKING_LIST',
      initialiseMarathonRanking: 'FETCH_DATA_MARATHON_RANKING_LIST',
      initialiseExplorerRanking: 'FETCH_DATA_EXPLORER_RANKING_LIST',
      initialiseWatermanRanking: 'FETCH_DATA_WATERMAN_RANKING_LIST',
    }),
    ...mapActions('group', {
      getUserGroups: 'FETCH_DATA_USER_GROUP_LIST',
    }),
    ...mapActions('discipline', {
      initialiseDisciplines: 'FETCH_DATA_DISCIPLINE_LIST',
    }),
    ...mapMutations('challenge', {
      clearRankingList: 'CLEAR_RANKING_LIST',
    }),
    ...mapMutations('group', {
      clearGroupList: 'CLEAR_USER_GROUP_LIST',
    }),
    ...mapMutations('discipline', {
      clearDisciplineList: 'CLEAR_DISCIPLINE_LIST',
    }),
    tabClick(index, groupid) {
      this.activetab = index;
      this.activegroup = groupid;
      this.rankingPage = 1;

      const filter = _.clone(this.rankingFilter);
      filter.page = this.rankingPage;
      filter.disciplineId = this.selectedDiscipline.id;
      filter.loadButton = false;
      filter.groupid = groupid;
      this.clearRankingList();

      this.getRankings(filter);
    },
    selectChanged(discipline) {
      const filter = _.clone(this.rankingFilter);
      filter.page = this.rankingPage;
      filter.disciplineId = discipline.id;
      filter.loadButton = false;
      this.clearRankingList();

      this.getRankings(filter);
    },
    getRankings(filter) {
      if (this.challenge.id == 1) this.initialiseYearRanking(filter);
      if (this.challenge.id == 2) this.initialiseMarathonRanking(filter);
      if (this.challenge.id == 3) this.initialiseExplorerRanking(filter);
      if (this.challenge.id == 4) this.initialiseWatermanRanking(filter);
    },
    loadMoreRanks() {
      this.rankingPage += 1;

      const filter = _.clone(this.rankingFilter);
      filter.page = this.rankingPage;
      filter.loadButton = true;
      filter.disciplineId = this.selectedDiscipline.id;
      filter.groupId = this.activegroup;

      this.getRankings(filter);
    },
    async loadGroups() {
      if (this.currentUser) {
        const filter = _.clone(this.getUserGroupFilter);
        filter.page = this.groupPage;
        filter.loadButton = true;
        await this.getUserGroups(filter);
      }
    },
  },
  created() {
    this.initialiseChallenges().then(() => {
      this.challenge = this.challenges.find((challenge) => challenge.name == this.$route.params.challengename.replace('-', ' '));
      this.clearRankingList();
      this.initialiseChallenge(this.challenge.id);
      this.getRankings(this.rankingFilter);
      if (this.challenge.id != 4) {
        this.initialiseDisciplines();
        this.isWatermanChallenge = false;
      }
      switch (this.challenge.name) {
        case '365 Challenge':
          this.imageName = '365';
          break;
        case 'Marathon Challenge':
          this.imageName = 'marathon';
          break;
        case 'Explorer Challenge':
          this.imageName = 'explorer';
          break;
        case 'Waterman Challenge':
          this.imageName = 'waterman';
          break;
        default:
          break;
      }
    });

    this.clearGroupList();
    this.loadGroups().then(() => {
      if (this.$route.params.groupname) this.activetab = this.groups.results.map((group) => group.name).indexOf(this.$route.params.groupname) + 2;
    });
  },
};
